.filters{
    .close{
            line-height: 0.4;
            margin-left: 10px;
    }
    .line-izq{
        font-size: 45%;
        vertical-align: middle;
        display: inline-block;
    }
    .chip{
        max-width: 100%;
        position: relative;
        display: inline-block;
    }
    .show-filters{
        cursor: pointer;
    }
    .search{
        align-items: flex-end;
        .form-control {
            border: none;
            background-color: transparent;
            border-bottom: 1px solid #424242;
            border-radius: 0;
        }
        .form-group{
            position: relative;
            .icon-search{
                position: absolute;
                right: 0;
                top: 7px;
            }
            .chip-close{
                padding: 0px 4px 2px 4px;
                line-height: 1;
                font-weight: bold;
                margin-left: 5px;
                position: relative;
                cursor: pointer;
                display: none;
                font-size:21px;
            
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .filters{
        .chip-close{
            display: inline-block !important;
            width: 26px;
            text-align: center;
            padding-bottom: 2px;
        }
    }
}