.overview{
    padding-top: 100px;
    padding-bottom: 64px;
}

.overview{
    .nav-item {
        border: 1px solid #dee2e6;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .activeTabs {
        background: #fff;
        color:#000
    }

    
}


.tab-content {
    border: 1px solid #dee2e6;
    background: rgb(255, 255, 255);
    padding-top: 2em;;
}
