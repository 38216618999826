.login{
    max-width: 600px !important;
    img{
        width: 100%;
        max-width: 230px;
    }
    .form-control:active, input:focus{
        outline: 0;
        border-color: #ff7534;
        box-shadow: none;
    }
    .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
        box-shadow: none;
    }
}