.date-picker{
    position: fixed;
    float: right;
    z-index: 1999;
    top: 14px;
    right: 120px;
    max-width: 1000px !important;
    div {
        margin:0 !important;
    }
    
    hr{
        height: 2px;
        background: rgb(255, 255, 255);
    }
    .text_descarga{
        color: #ffffff;
    }
    @media (max-width: 1200px) {
        position: inherit;
        float: inherit;
        margin-bottom: 2em;
        .text_descarga{
            color: #424242;
        }
    }
}