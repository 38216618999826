.service-unanswered, .product-unanswered, .time-unanswered, .other-unanswered{
    width: 10px;
    height: 10px;
    background: #eaebf2;
    display: inline-block;
    margin-right: 5px;
}

.service-answered{
    width: 10px;
    height: 10px;
    background: #f9cb11;
    display: inline-block;
    margin-right: 5px;
}
.product-answered{
    width: 10px;
    height: 10px;
    background: #29bff0;
    display: inline-block;
    margin-right: 5px;
}
.time-answered{
    width: 10px;
    height: 10px;
    background: #99de14;
    display: inline-block;
    margin-right: 5px;
}
.other-answered{
    width: 10px;
    height: 10px;
    background: #ff7534;
    display: inline-block;
    margin-right: 5px;
}

.no-answered{
    width: 10px;
    height: 10px;
    background: #eaebf2;
    display: inline-block;
    margin-right: 5px;
}