.screenLoader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    background-color: #f9763450;
    left: 0;
    top: 0;

    img {
        width: 200px;
        height: auto;
    }
}