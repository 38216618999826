.chat{
    padding-bottom: 50px;
    .form-control:active, input:focus{
        outline: 0;
        border-color: #ff7534;
        box-shadow: none;
    }
    .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
        box-shadow: none;
    }
    &:first-child {
        margin-top: 3em;
    }
}

.chat_box {
    margin-top: 5em;
}

.chat_icon {
    font-size: 2em;
}

.chat-text {
    font-size: 1em
}

.operator {
    padding-left: 30% !important;
    text-align: right;
}

.client {
    margin-right: 30% !important;
    text-align: left;
}

.imgChat {
    max-width: 300px;
    margin: auto;
}

.dots {
    display: block;
    width: 100%;
    text-align: right;
    img {
        width: 50px;
    }
}

.chat_msn_box {
    height: 43vh;
    overflow: hidden;
    overflow-y: scroll;
}

.auto-response-icon {

    width: 40px !important;

}

.auto-response-box {

    background-color: #ffbea269;
    padding-top: 10px;
    .col {
        p.emigre {
            small {
                color: #fff !important
            }
        }
    }

}

.outline-bt {
    background-color: #fff !important;
    border-color: #ff7534 !important;
    color: #ff7534 !important;
    &:hover {
        background-color:  #ff7534 !important;
        color: #fff !important;
    }
}