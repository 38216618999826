.mantenimiento {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8671a;
    img {
        width: 100%;
        height: auto;
    }
}