/* COMPONENTE */
.whiteCapsule{
    border: 1px solid #dee2e6;
    border-radius: 10px;
    width: 100%;
    min-width: 59px;
    padding: 8px 15px;
    white-space: nowrap;
    text-align: center;
}

.grayCapsule{
    border: 1px solid #424242;
    background-color: #424242;
    border-radius: 10px;
    width: 100%;
    min-width: 59px;
    color: #fff;
    padding: 8px 15px;
    white-space: nowrap;
    text-align: center;
}

.top_5 {
    list-style-type: none;
}

.badge-line1::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: #BCBFCF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30%;
    padding-left: 5px;
}